/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
ul.company-details > li {
  font-size: 0.95em;
  margin-bottom: 4px;
}
.model-display-name {
  color: #8c8c8c;
  font-size: 0.9em;
  margin-top: -4px;
}
